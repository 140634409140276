import styled from "styled-components/macro";
import checkboxIcon from "../assets/images/Checkbox.png";
import checkboxBaseIcon from "../assets/images/_Checkbox_base.png";
import darkCheckboxBaseIcon from "../assets/images/dark_base.png";
import darkcheckIcon from "../assets/images/darkcheckIcon.png";
import checkbox_minus_light from "../assets/images/checkbox_minus_light.png";
import checkbox_minus_dark from "../assets/images/checkbox_minus_dark.png";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const SectionContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#f6f7f9")};
`;
export const InnerContainer = styled.div`
  /* height: 100%; */
  /* min-height: 720px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1.5rem;

  @media (max-width: 510px) {
    padding: 0 0.7rem;
  }
`;
export const BannerBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const TitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;

  @media (max-width: 769px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const TitleBox = styled.div`
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-direction: column;
`;
export const HeadTitle = styled.div`
  font-size: 1.6rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  font-weight: 500;
  font-family: "EB Garamond", serif;
  text-transform: capitalize;

  span {
    font-size: 1.2rem;
    color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
    font-weight: 500;
    font-family: "EB Garamond", serif;
  }
`;
export const HeadDesc = styled.div`
  font-size: 0.7rem;
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-weight: 400;
  margin-top: 3px;
  text-transform: none;
`;
export const BackButton = styled.div`
  /* font-size: 0.7rem;
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-weight: 400;
  margin-top: 3px;
  text-transform: none;
  border: ${(props) =>
    props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 6px;
    padding: 5px;
    cursor: pointer; */

  img {
    height: 14px;
    width: 20px;
    object-fit: contain;
    cursor: pointer;
  }
`;
export const AddCommonButton = styled.div`
  color: #ffffff;
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0.85rem 1rem 0.85rem 1.3rem;
  gap: 0.5rem;
  cursor: pointer;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    margin-top: 1rem;
  }
`;
export const AddCommonButtonMaster = styled.div`
  color: #ffffff;
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0.85rem 1rem 0.85rem 1.3rem;
  gap: 0.5rem;
  cursor: pointer;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    display: none;
  }
`;
export const DownloadMaster = styled.div`
  background-color: ${(props) =>
    props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
  border: ${(props) =>
    props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem 1.5rem;
  border-radius: 8px;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }
  @media (max-width: 769px) {
    display: flex;
  }
`;
export const GreenContinue = styled.div`
  color: #ffffff;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: #17b26a;
  border-radius: 8px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0.85rem 1rem 0.85rem 1.3rem;
  gap: 0.5rem;
  margin: 2rem 0 1rem 0;
  cursor: pointer;

  @media (max-width: 769px) {
    margin-top: 1rem;
  }
`;
export const AddCommonButtonBadge = styled.div`
  color: #ffffff;
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0.85rem 0.7rem 0.85rem 0.7rem;
  gap: 0.5rem;
  cursor: pointer;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    margin-top: 1rem;
  }
`;
export const AddCommonButtons = styled.div`
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: none;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 856px) {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      display: none;
    }
    img {
      margin: 0;
    }
  }
`;
export const AddCommonButtonLearner = styled.div`
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin: 0;
    }
  }
`;
export const AddCommonButtonLearnerLo = styled.div`
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  p {
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 600;
  }

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    justify-content: center;
    align-items: center;
    p {
      display: none;
    }
    img {
      margin: 0;
    }
  }
`;
export const AddCommit = styled.div`
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  gap: 7px;

  p {
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 600;
  }

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    justify-content: center;
    align-items: center;
    p {
      display: none;
    }
    img {
      margin: 0;
    }
  }
`;
export const AddCommonButtonLearnerCom = styled.div`
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    img {
      margin: 0;
    }
  }
`;
export const AddCommonButtonLearnerResp = styled.div`
  color: #ffffff;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: none;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin: 0;
    }
  }
`;
export const AddCommonButtonCourse = styled.div`
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin: 0;
    }
  }
`;
export const ParentTable = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-transform: none !important;
  .ant-table-selection-column {
    padding-left: 30px !important;
    width: 50px !important;
  }
  @media (max-width: 769px) {
    margin-bottom: 6rem;
  }
`;
// export const TableBox = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: flex-start;
//   margin-top: 1rem;
//   text-transform: none !important;

//   .ant-table-thead .ant-checkbox .ant-checkbox-inner {
//     height: 24px !important;
//     width: 24px !important;

//     background: ${(props) =>
//       props.themeColor
//         ? `url(${darkCheckboxBaseIcon}) no-repeat center center !important`
//         : `url(${checkboxBaseIcon}) no-repeat center center !important`};
//     background-size: contain !important;
//     background-color: transparent !important;
//     border: 2px solid transparent !important;
//   }
//   td.ant-table-cell.ant-table-cell-scrollbar {
//     background-color: ${(props) =>
//       props.themeColor ? "#1F242F" : "#FFFFFF"} !important;
//     box-shadow: none !important;
//   }
//   .ant-table-thead .ant-checkbox .ant-checkbox-inner:after {
//     height: 21px !important;
//     width: 21px !important;
//     background-color: transparent !important;
//     background: ${(props) =>
//       props.themeColor
//         ? `url(${checkbox_minus_dark}) no-repeat center center !important`
//         : `url(${checkbox_minus_light}) no-repeat center center !important`};
//     background-size: contain !important;
//     border-color: transparent !important;
//   }
//   .ant-checkbox-checked .ant-checkbox-inner:after {
//     height: 21px !important;
//     width: 21px !important;
//     margin-left: 7px !important;
//     margin-top: 0.8px !important;
//     transform: rotate(0deg) scale(1) translate(-50%, -50%) !important;
//     background: ${(props) =>
//       props.themeColor
//         ? `url(${darkcheckIcon}) no-repeat center center !important`
//         : `url(${checkboxIcon}) no-repeat center center !important`};
//             background-size: contain !important;
//             border-color: transparent !important;
//   }
//   && .ant-table-column-sorter {
//     color: ${(props) =>
//       props.themeColor
//         ? "#FFFFFF"
//         : "#0C111D"} !important; // Use !important to override existing styles
//   }

//   &&&
//     :where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
//     .ant-table-pagination-right {
//     background-color: ${(props) =>
//       props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
//     border-top: ${(props) =>
//       props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
//     border-radius: 0 0 8px 8px !important;
//   }
//   &&& :where(.css-p7e5j5).ant-table-wrapper .ant-table-pagination-right {
//     background-color: ${(props) =>
//       props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
//     border-top: ${(props) =>
//       props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
//     border-radius: 0 0 8px 8px !important;
//   }

//   /* .ant-table-thead > tr > th {
//     background-color: ${(props) =>
//     props.themeColor ? "#1F242F" : "#FFFFFF"} !important;
//     border-bottom: ${(props) =>
//     props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"} !important;
//   }

//   .ant-table-thead .ant-table-cell {
//     border-right: ${(props) =>
//     props.themeGrid ? "none" : "1px solid black"} !important;
//   } */

//   @media (max-width: 769px) {
//     display: none;
//   }
// `;
export const TableBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1rem;
  text-transform: none !important;

  .ant-table-thead .ant-checkbox .ant-checkbox-inner:after {
    height: ${(props) => (props.checkCon ? "" : "21px !important")};
    width: ${(props) => (props.checkCon ? "" : "21px !important")};
    background-color: transparent !important;
    background: ${(props) =>
      props.checkCon
        ? ""
        : props.themeColor
        ? `url(${checkbox_minus_dark}) no-repeat center center !important`
        : `url(${checkbox_minus_light}) no-repeat center center !important`};
    background-size: contain !important;
    border-color: transparent !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    height: ${(props) => (props.checkCon ? "21px !important" : "")};
    width: ${(props) => (props.checkCon ? "21px !important" : "")};
    margin-left: 7px !important;
    margin-top: 0.8px !important;
    transform: rotate(0deg) scale(1) translate(-50%, -50%) !important;
    background: ${(props) =>
      props.checkCon
        ? props.themeColor
          ? `url(${darkcheckIcon}) no-repeat center center !important`
          : `url(${checkboxIcon}) no-repeat center center !important`
        : ""};
    background-size: contain !important;
    border-color: transparent !important;
  }

  && .ant-table-column-sorter {
    color: ${(props) =>
      props.themeColor
        ? "#FFFFFF"
        : "#0C111D"} !important; // Use !important to override existing styles
  }

  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
    .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }

  &&& :where(.css-p7e5j5).ant-table-wrapper .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }

  /* update */
  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-checkbox
    .ant-checkbox-inner {
    border-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#333741 !important"
        : "#b3b9c6 !important"};
    background-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#0C111D !important"
        : ""};
    border-radius: 6px;
    height: 21px !important;
    width: 21px !important;
  }
  &&& :where(.css-p7e5j5).ant-checkbox .ant-checkbox-inner {
    border-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#333741 !important"
        : "#b3b9c6 !important"};
    background-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#0C111D !important"
        : ""};
    border-radius: 6px;
    height: 21px !important;
    width: 21px !important;
  }
  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: none !important;
  }
  &&&
    :where(.css-p7e5j5).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: none !important;
  }

  @media (max-width: 769px) {
    display: none;
  }
`;
export const TableBoxCerti = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1rem;
  text-transform: none !important;

  .ant-table-thead .ant-checkbox .ant-checkbox-inner {
    height: 24px !important;
    width: 24px !important;

    background: ${(props) =>
      props.themeColor
        ? `url(${darkCheckboxBaseIcon}) no-repeat center center !important`
        : `url(${checkboxBaseIcon}) no-repeat center center !important`};
    background-size: contain !important;
    background-color: transparent !important;
    border: 2px solid transparent !important;
  }
  td.ant-table-cell.ant-table-cell-scrollbar {
    background-color: ${(props) =>
      props.themeColor ? "#1F242F" : "#FFFFFF"} !important;
    box-shadow: none !important;
  }
  .ant-table-thead .ant-checkbox .ant-checkbox-inner:after {
    height: 21px !important;
    width: 21px !important;
    background-color: transparent !important;
    background: ${(props) =>
      props.themeColor
        ? `url(${checkbox_minus_dark}) no-repeat center center !important`
        : `url(${checkbox_minus_light}) no-repeat center center !important`};
    background-size: contain !important;
    border-color: transparent !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    margin-left: 7px !important;
    margin-top: 0.8px;
    transform: rotate(0deg) scale(1) translate(-50%, -50%) !important;
    background: ${(props) =>
      props.themeColor
        ? `url(${darkcheckIcon}) no-repeat center center !important`
        : `url(${darkcheckIcon}) no-repeat center center !important`};
  }
  && .ant-table-column-sorter {
    color: ${(props) =>
      props.themeColor
        ? "#FFFFFF"
        : "#0C111D"} !important; // Use !important to override existing styles
  }

  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
    .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }
  &&& :where(.css-p7e5j5).ant-table-wrapper .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }

  /* .ant-table-thead > tr > th {
    background-color: ${(props) =>
    props.themeColor ? "#1F242F" : "#FFFFFF"} !important;
    border-bottom: ${(props) =>
    props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"} !important;
  }

  .ant-table-thead .ant-table-cell {
    border-right: ${(props) =>
    props.themeGrid ? "none" : "1px solid black"} !important;
  } */

  thead.ant-table-thead {
    font-size: 10px !important;
  }
`;
export const TableBoxLearner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1rem;
  text-transform: none !important;

  .ant-table-thead .ant-checkbox .ant-checkbox-inner {
    height: 24px !important;
    width: 24px !important;

    /* background: ${(props) =>
      props.themeColor
        ? `url(${darkCheckboxBaseIcon}) no-repeat center center !important`
        : `url(${checkboxBaseIcon}) no-repeat center center !important`}; */
    background-size: contain !important;
    background-color: transparent !important;
    border: 2px solid transparent !important;
  }
  td.ant-table-cell.ant-table-cell-scrollbar {
    background-color: ${(props) =>
      props.themeColor ? "#1F242F" : "#FFFFFF"} !important;
    box-shadow: none !important;
  }
  .ant-table-thead .ant-checkbox .ant-checkbox-inner:after {
    height: ${(props) => (props.checkCon ? "" : "21px !important")};
    width: ${(props) => (props.checkCon ? "" : "21px !important")};
    background-color: transparent !important;
    background: ${(props) =>
      props.checkCon
        ? ""
        : props.themeColor
        ? `url(${checkbox_minus_dark}) no-repeat center center !important`
        : `url(${checkbox_minus_light}) no-repeat center center !important`};
    background-size: contain !important;
    border-color: transparent !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    height: ${(props) => (props.checkCon ? "21px !important" : "")};
    width: ${(props) => (props.checkCon ? "21px !important" : "")};
    margin-left: 7px !important;
    margin-top: 0.8px !important;
    transform: rotate(0deg) scale(1) translate(-50%, -50%) !important;
    background: ${(props) =>
      props.checkCon
        ? props.themeColor
          ? `url(${darkcheckIcon}) no-repeat center center !important`
          : `url(${checkboxIcon}) no-repeat center center !important`
        : ""};
  }

  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
    .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }
  &&& :where(.css-p7e5j5).ant-table-wrapper .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }

  .border-border:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }

  /* update */
  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-checkbox
    .ant-checkbox-inner {
    border-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#333741 !important"
        : "#b3b9c6 !important"};
    border-width: 1px !important;
    background-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#0C111D !important"
        : "#fff !important"};
    border-radius: 6px;
    height: 21px !important;
    width: 21px !important;
  }
  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: none !important;
  }
  @media (max-width: 769px) {
    display: none;
  }
  &&& :where(.css-p7e5j5).ant-checkbox .ant-checkbox-inner {
    border-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#333741 !important"
        : "#b3b9c6 !important"};
    border-width: 1px !important;
    background-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#0C111D !important"
        : "#fff !important"};
    border-radius: 6px;
    height: 21px !important;
    width: 21px !important;
  }
  &&&
    :where(.css-p7e5j5).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: none !important;
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
export const TableCard = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 1rem 1.6rem;
  margin-top: 1rem;
  border-radius: 8px;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border: ${(props) =>
    props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
  @media (max-width: 769px) {
    display: flex;
  }
  @media (max-width: 769px) {
    padding: 1rem;
    margin-top: 0.9rem;
  }
`;
export const TableCardBulk = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 1rem 1.6rem;
  margin-top: 1rem;
  border-radius: 8px;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border: ${(props) =>
    props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
  @media (max-width: 769px) {
    display: flex;
  }
  @media (max-width: 769px) {
    padding: 1rem;
    margin-top: 0.9rem;
    box-shadow: none;
  }
`;
export const TableTitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
`;
export const TableTitleRowDusra = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 1rem;
  gap: 1rem;
  @media (max-width: 364px) {
    gap: 0.4rem;
  }
`;
export const ComShowButton = styled.div`
  background-color: ${(props) =>
    props.backColor
      ? props.themeColor
        ? "#161B26"
        : props.backColor
      : "#363a44"};
  color: ${(props) =>
    props.colorText
      ? props.themeColor
        ? "#94969C"
        : props.colorText
      : "#363a44"};
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid transparent"};
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px;
  text-transform: capitalize;
  display: flex;
  gap: 0.6rem;
  align-items: center;
  border-radius: 4px;
  @media (max-width: 463px) {
    font-size: 0.8rem;
  }
`;
export const CheckRowBox = styled.div`
  width: 5%;
  display: flex;
  justify-content: left;
  align-items: flex-start;

  @media (max-width: 488px) {
    width: 10%;
  }
`;
export const TitleRowFirst = styled.div`
  width: ${(props) => (props.wiDth ? props.wiDth : "90%")};
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  gap: 1rem;
  @media (max-width: 488px) {
    width: 100% !important;
  }
  @media (max-width: 346px) {
    width: 100% !important;
  }
`;
export const RowBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;
export const DocuemntTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  text-align: left;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363a44")};
`;
export const OtherPolicyTitle = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: capitalize;
  text-align: left;
  color: ${(props) => (props.themeColor ? "#94969C" : "#3D424F")};
`;
export const ComContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: -3rem;
`;
export const TitleRowInnerFirst = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  align-items: center;
`;
export const TitleRowInnerFirstUser = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  align-items: center;
`;
export const TitleRowInnerFirstUsers = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  align-items: center;
`;
export const TitleRowInnerFirstUsersLang = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  align-items: center;
`;
export const TitleRowInnerFirsts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  align-items: center;
`;
export const BoxesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const FirstRowBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.7rem;
`;
export const TitleRowInnerSecond = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
`;
export const TitleRowFirstTitle = styled.div`
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363a44")};
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 463px) {
    font-size: 0.9rem;
  }
  @media (max-width: 375px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const TitleRowFirstTitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363a44")};
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  p {
  }
  @media (max-width: 463px) {
    font-size: 0.9rem;
  }
  @media (max-width: 375px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const TitleRowFirstDesc = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#464C5E")};
  text-transform: none;
  margin-top: 0.6rem;
  white-space: nowrap;
  /* width: 50px;  */
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 463px) {
    font-size: 0.9rem;
  }
`;
export const TitleRowFirstDescQuiz = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#464C5E")};
  text-transform: none;
  margin-top: 0.6rem;
  white-space: nowrap;
  /* width: 50px;  */
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 463px) {
    font-size: 0.9rem;
  }
`;
export const TitleRowFirstDescGrey = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#8A94A6")};
  text-transform: capitalize;
  margin-top: 0.6rem;
  white-space: nowrap;
  /* width: 50px;  */
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 463px) {
    font-size: 0.9rem;
  }
`;
export const TitleRowFirstDescBulk = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#94969C" : "#363A44")};
  text-transform: capitalize;
  margin-top: 0.6rem;
  white-space: nowrap;
  /* width: 50px;  */
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 463px) {
    font-size: 0.9rem;
  }
`;
export const TitleRowFirstDate = styled.div`
  width: 100%;
  font-size: 0.9rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#94969C" : "#8a94a6")};
  margin-top: 5px;
`;
export const TitleRowFirstDateBlack = styled.div`
  width: 100%;
  font-size: 0.8rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#94969C" : "#464C5E")};
  margin-top: 5px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TitleRowFirstDateBulk = styled.div`
  width: 100%;
  font-size: 0.8rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  margin-top: 5px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const StatusButtonRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const TitleRowFirstDates = styled.div`
  width: 100%;
  font-size: 1.1rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#94969C" : "#8a94a6")};
  margin-top: 10px;
  @media (max-width: 463px) {
    font-size: 0.8rem;
  }
`;
export const TitleRowSecond = styled.div`
  width: 10%;
  display: none;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  position: relative;
  @media (max-width: 789px) {
    display: flex;
  }

  .dot-button {
    img {
      height: 20px;
    }
  }
`;
export const Message = styled.div`
  color: white;
  padding: 1rem;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 30px;
  gap: 0.5rem;
  z-index: 1;
  height: ${(props) => (props.isHeight ? props.isHeight : "80px")};
  width: 100px;
  position: absolute;
  top: 0;
  margin-top: 2.1rem;
  margin-right: -1rem;
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#ffffff")};
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: ${(props) => (props.dropdownOpen ? "flex" : "none")};
  flex-direction: column;

  &:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: ${(props) =>
      props.themeColor ? "#161B26" : "#D7DAE0"};

    border-width: 10px;
    margin-left: 65px;
  }
`;
export const MenuRow = styled.li`
  width: 100%;
  display: flex;
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  align-items: center;
  font-weight: 500;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 8px;
  }
`;
export const TableContentBox = styled.div`
  width: 90%;
  flex-wrap: wrap;
  color: ${(props) => (props.themeColor ? "#94969C" : "#464c5e")};
  font-weight: 400;
  font-size: 0.9rem;
  margin-top: 1rem;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines after which text will be truncated */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.6em; /* Adjust based on your requirements */
  height: 3.2em; /* Height is 2 times the line-height */
  word-wrap: break-word;
`;
export const TableContentBoxDesc = styled.div`
  width: 100%;
  flex-wrap: wrap;
  color: ${(props) => (props.themeColor ? "#94969C" : "#464c5e")};
  font-weight: 400;
  font-size: 0.8rem;
  margin-top: 0.3rem;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines after which text will be truncated */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.6em; /* Adjust based on your requirements */
  word-wrap: break-word;
`;
export const ContentRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ComContentBox = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  @media (max-width: 438px) {
    width: 50%;
  }
`;
export const RadioRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  p {
    font-weight: 500;
    font-size: 0.9rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#363A44")};
  }

  @media (max-width: 438px) {
    p {
      font-weight: 500;
      font-size: 0.8rem;
      color: ${(props) => (props.themeColor ? "#94969C" : "#363A44")};
    }
  }
`;
export const SearchFilterRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  gap: 1rem;
  position: relative;
`;
export const SearchFilterRowBulk = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  gap: 1rem;
  position: relative;
  padding: 0rem 1.5rem;

  @media (max-width: 769px) {
    padding: 0;
    margin-top: 0rem;
  }
`;
export const SearchFilterRowReport = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
  position: relative;
`;
export const SearchFilterRowClient = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;

  @media (max-width: 769px) {
    flex-direction: column;
  }
`;
export const SearchFilterRowLearner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
  padding: 0 1.5rem;

  @media (max-width: 769px) {
    padding: 0;
    margin: 0;
  }
`;
export const SearchBox = styled.div`
  display: flex;
  width: 25%;
  /* background-color: #ffffff; */
  align-items: center;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  border-radius: 8px;
  padding: 12px 14px;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};

  input {
    border: none;
    width: 100%;
    outline: none;
    background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
    color: ${(props) => (props.themeColor ? "#ffffff" : "#0C111D")};
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#85888E" : "#8A94A6")};
    border: none;
    outline: none;
    text-transform: capitalize;
  }

  img {
    height: 17px;
    width: 17px;
    margin-right: 0.5rem;
    object-fit: contain;
  }

  @media (max-width: 769px) {
    width: 100%;
  }
`;
export const FilterIconsContainer = styled.div`
  display: flex;

  gap: 8px;
`;
export const FilterIconBox = styled.div`
  height: 35px;
  width: 35px;
  cursor: pointer;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  i {
    color: ${(props) => (props.themeColor ? "#ffffff" : "#0C111D")};
  }
`;
export const FilterIconBoxAudit = styled.div`
  height: 38px;
  width: 38px;
  cursor: pointer;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const FilterRow = styled.div`
  width: auto;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.7rem;

  /* @media (max-width: 768px) {
    width: 20%;
  } */
`;
export const FilterRowBox = styled.div`
  width: 950px;
  display: flex;
  justify-content: ${(props) => (props.isAlign ? "flex-start" : "end")};
  align-items: center;
  overflow-x: scroll;
  gap: 10px;
`;
export const FilterBox = styled.div`
  display: flex;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  align-items: center;
  border: ${(props) =>
    props.themeColor
      ? "1px solid #333741"
      : props.isFilterOpen
      ? "1px solid #FBA976"
      : "1px solid #d7dae0"};
  box-shadow: ${(props) =>
    props.isFilterOpen ? "0px 0px 0px 2px #FEE8D6" : ""};
  border-radius: 8px;
  padding: 10px;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#464c5e")};
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  text-transform: capitalize;

  .text-display {
    display: flex;
    align-items: center;
  }

  .cross-icon {
    height: 10px;
    width: 10px;
    margin-left: 10px;
  }
  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 8px;
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
export const FilterBoxFilter = styled.div`
  display: flex;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  align-items: center;
  border: ${(props) =>
    props.themeColor
      ? "1px solid #333741"
      : props.isFilterOpen
      ? "1px solid #FBA976"
      : "1px solid #d7dae0"};
  box-shadow: ${(props) =>
    props.isFilterOpen ? "0px 0px 0px 2px #FEE8D6" : ""};
  border-radius: 8px;
  padding: 10px;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#464c5e")};
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  text-transform: capitalize;

  .text-display {
  }

  .cross-icon {
    height: 10px;
    width: 10px;
    margin-left: 10px;
  }
  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 8px;
  }
  @media (max-width: 769px) {
    padding: 16px;
    img {
      margin: 0;
    }
    .text-display {
      display: none;
    }
  }
`;
export const FilterBoxResp = styled.div`
  display: none;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  align-items: center;
  border: ${(props) =>
    props.themeColor
      ? "1px solid #333741"
      : props.isFilterOpen
      ? "1px solid #FBA976"
      : "1px solid #d7dae0"};
  box-shadow: ${(props) =>
    props.isFilterOpen ? "0px 0px 0px 2px #FEE8D6" : ""};
  border-radius: 4px;
  padding: 10px;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#464c5e")};
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 1rem;
  cursor: pointer;
  text-transform: capitalize;

  .text-display {
  }

  .cross-icon {
    height: 10px;
    width: 10px;
    margin-left: 10px;
  }
  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 8px;
  }
  @media (max-width: 769px) {
    img {
      margin: 0;
    }
    display: flex;
  }
`;

export const ZohoBox = styled.div`
  display: flex;
  background-color: transparent;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  text-transform: capitalize;

  .text-display {
  }
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-right: 8px;
  }
  @media (max-width: 769px) {
    width: 100%;
    justify-content: right;
    align-items: center;
    img {
      /* margin: 0; */
    }
  }
`;
export const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
export const InnerOverlayContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: end;
  /* align-items: center; */
`;
export const BlurBox = styled.div`
  height: 100%;
  width: 50%;
  background-color: black;
  opacity: 0.5;

  @media (max-width: 768px) {
    display: none;
  }
`;
export const OverlayBox = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#ffffff")};
  /* align-items: center; */
  padding: 2rem 1rem;
  overflow-y: scroll;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const OverlayCrosRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;

  @media (max-width: 769px) {
    justify-content: flex-start;
  }
`;
export const CrossButton = styled.div`
  cursor: pointer;
  img {
    height: 13px;
    width: 13px;
    object-fit: contain;
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
export const CrossButtons = styled.div`
  cursor: pointer;
  display: none;
  img {
    height: 13px;
    width: 13px;
    object-fit: contain;
  }
  @media (max-width: 769px) {
    margin-bottom: 10px;
    display: flex;
  }
`;
export const ProfileRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  /* margin-bottom: 1rem; */
  @media (max-width: 962px) {
    gap: 0.3rem;
  }
`;
export const ProfileBox = styled.div`
  width: 12%;
  display: flex;
  justify-content: left;
  align-items: center;
`;
export const ProfileImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) =>
    props.themeColor ? "1px solid #334155" : "1px solid #D7DAE0"};
  border-radius: 8px;

  img {
    height: 70px;
    width: 70px;
    object-fit: contain;
  }
  @media (max-width: 962px) {
    /* border-radius: 50%; */
  }
  @media (max-width: 470px) {
    /* overflow: hidden; */
    img {
      height: 50px;
      width: 50px;
      object-fit: cover;
    }
  }
`;
export const ProfileContentBox = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
  @media (max-width: 962px) {
    margin-left: 15px;
  }
  @media (max-width: 769px) {
    margin-left: 0;
  }
  @media (max-width: 962px) {
    margin-left: 20px;
  }
  @media (max-width: 872px) {
    margin-left: 25px;
  }
  @media (max-width: 779px) {
    margin-left: 30px;
  }
  @media (max-width: 769px) {
    margin-left: 0px;
  }
  @media (max-width: 610px) {
    margin-left: 5px;
  }
  @media (max-width: 560px) {
    margin-left: 15px;
  }
  @media (max-width: 480px) {
    margin-left: 20px;
  }
`;
export const ButtonBox = styled.div`
  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }

  img {
    height: 22px;
    width: 22px;
    object-fit: contain;
  }
`;
export const ProfileTitle = styled.div`
  width: 100%;
  font-size: 1.2rem;
  color: ${(props) => (props.themeColor ? "#FAFAFA" : "#24262d")};
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;

  @media (max-width: 470px) {
    font-size: 1rem;
  }
`;
export const ProfileRowBox = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    flex-direction: column;
    margin-top: 0.4rem;
    span {
      display: non;
    }
  }
  /* justify-content: left; */
  /* align-items: center; */
`;
export const ProfileDescRow = styled.div`
  width: 100%;
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
`;
export const ProfileDescRowCon = styled.div`
  /* width: 1005; */
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;

  p {
    line-height: 22px;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    cursor: pointer;
  }
  .lang-gap {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  /* @media (max-width: 769px) {
    width: 50%;
    margin-top: 0.2rem;
  } */
`;
export const CommonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
`;
export const ProfileSubTitle = styled.div`
  width: 100%;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-bottom: 0.8rem;
`;
export const ProfileSubDesc = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  p {
    font-size: 0.9rem;
    color: ${(props) => (props.themeColor ? "#ECECED" : "#667085")};
    font-weight: 400;
    line-height: 20px;
  }
`;
export const MediaRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741;" : "1px solid #d7dae0"};
  background-color: ${(props) =>
    props.themeColor ? "#0C111D" : "transparent"};
  border-radius: 8px;
  padding: 12px 14px;
  margin-bottom: 10px;
`;
export const MediaBox = styled.div`
  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
  }
`;
export const MediaContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
export const MediaTitle = styled.div`
  width: 100%;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363A44")};

  @media (max-width: 411px) {
    width: 150px;
  }
`;
export const MediaDescBox = styled.div`
  width: 100%;
  display: flex;
`;
export const MediaDescText = styled.div`
  font-size: 0.7rem;
  font-weight: 400;
  text-align: left;
  color: #8a94a6;
  display: flex;
`;

export const CheckBoxRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #8a94a6;
  font-weight: 500;

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-left: 10px;
  }
`;
export const CourseTitle = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid transparent" : "1px solid #d7dae0"};
  padding-bottom: 10px;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
`;
export const VideoRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741;" : "1px solid #d7dae0"};
  background-color: ${(props) =>
    props.themeColor ? "#0C111D" : "transparent"};
  border-radius: 8px;
  padding: 12px 14px;
  margin-bottom: 10px;
`;
export const FileRow = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;
export const VideoImageBox = styled.div`
  img {
    height: 70px;
    width: 100px;
    object-fit: contain;
    border-radius: 8px;
  }
  .play-butttonon {
    position: absolute;
    /* top: 50%; */
    height: 25px;
    width: 25px;
    object-fit: contain;
    margin-top: 1.8rem;
    margin-left: -4rem;
    cursor: pointer;
  }
`;
export const VideoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* justify-content: space-between; */
  margin-left: 8px;
`;
export const VideoTitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
`;
export const VideoTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  button {
    border: none;
    outline: none;
    background: transparent;
  }
  img {
    height: 10px;
    width: 10px;
    object-fit: contain;
  }
`;
export const MinBox = styled.div`
  font-size: 0.8rem;
  color: #667085;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#667085")};
  width: 100%;
  margin: 7px 0;
`;
export const TagsBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const TagsTitle = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363A44")};
  width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TagsRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
export const ComTags = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#f6f7f9")};
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid transparent"};
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-size: 0.8rem;
  padding: 5px 7px;
  font-weight: 500;
  margin-right: 7px;
  border-radius: 4px;
`;
export const DescContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.8rem;
  color: #667085;
  font-weight: 400;
  line-height: 17px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`;
export const FeedbackRow = styled.div`
  width: 100%;
  display: flex;
  border: ${(props) =>
    props.activeColor
      ? "1px solid #FBA976"
      : props.themeColor
      ? "1px solid #333741"
      : "1px solid #edeef1"};
  border-radius: 8px;
  align-items: flex-start;
  padding: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  @media (max-width: 424px) {
    flex-direction: column;
  }
`;
export const ComFeedbackBox = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  @media (max-width: 640px) {
    width: 25%;
    margin-top: 1rem;
  }
  @media (max-width: 511px) {
    width: 30%;
  }
  @media (max-width: 424px) {
    width: 100%;
  }
`;
export const ComFeedbackRow = styled.div`
  /* width: 16.6%; */
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
`;
export const ComFeedbackRowCourse = styled.div`
  /* width: 16.6%; */
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const FeedbackTitle = styled.div`
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#667085")};
  font-size: 0.7rem;
  font-weight: 500;
`;
export const FeedbackOrangeText = styled.div`
  color: #f6591c;
  /* color: ${(props) => (props.activeColor ? "#f6591c" : "#667085")}; */
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
`;
export const FeedbackText = styled.div`
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  /* color: ${(props) => (props.activeColor ? "#f6591c" : "#667085")}; */
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
`;
export const CheckBox = styled.div`
  cursor: pointer;
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-left: 10px;
  }
`;
export const CheckBoxs = styled.div`
  cursor: pointer;
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-right: 10px;
  }
`;
export const FeedbackDesc = styled.div`
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  font-weight: 500;
  font-size: 0.8rem;
  margin-top: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const InnerSection = styled.div`
  width: 100%;
  text-align: center;

  .otp {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 80%;
    align-items: center;
    margin: auto;
    margin-bottom: 1rem;
  }
  .verifyDiv {
    padding: 18px;
  }
  p {
    color: white;
    margin-bottom: 0px;
  }
  .inputStyle {
    width: 60px !important;
    height: 60px;
    border-radius: 7px;
    border: 0px;
    margin-left: 8px;
    margin-right: 8px;
    background: #dddddd;
    font-size: 2rem;
    outline: none;
    color: #363a44;
    font-weight: 600;
  }
  .inputStyle-error {
    width: 60px !important;
    height: 60px;
    border-radius: 7px;
    border: 1px solid red;
    margin-left: 8px;
    margin-right: 8px;
    background: transparent;
    font-size: 2rem;
    outline: none;
    color: red;
    font-weight: 600;
  }
  .inputStyle-success {
    width: 50% !important;
    height: 60px;
    border-radius: 7px;
    border: 1px solid #17b26a;
    margin-left: 8px;
    margin-right: 8px;
    background: transparent;
    font-size: 2rem;
    outline: none;
    color: #17b26a;
    font-weight: 600;
  }
  .p3 {
    font-size: 14px;
  }
  .resend {
    text-decoration: underline;
    font-size: 14px;
  }
  .otpElements {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 20px;
    margin-bottom: 50px;
    padding-left: 18px;
    justify-content: center;
  }

  .p1 {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    margin-top: 25px;
    font-size: 27px;
  }

  .p2 {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
  }
`;
export const InnerSectionSuccess = styled.div`
  width: 100%;
  text-align: center;
  width: 300px !important;
`;
export const InnerSectionSuccessTwo = styled.div`
  width: 100%;
  text-align: center;
  width: 100% !important;
`;
export const InnerSectionWarning = styled.div`
  width: 100%;
  text-align: left;
  width: 350px;
  text-transform: capitalize;
`;
export const InnerSectionWarningTwo = styled.div`
  width: 100%;
  text-align: left;
  text-transform: capitalize;
`;
export const InnerSections = styled.div`
  width: 100%;
  text-align: left;
`;
export const InnerSectionsBanner = styled.div`
  width: 100%;
  min-width: 600px;
  text-align: left;

  @media (max-width: 769px) {
    min-width: 520px;
  }
  @media (max-width: 612px) {
    min-width: 420px;
  }
  @media (max-width: 512px) {
    min-width: 350px;
  }
  @media (max-width: 435px) {
    min-width: 300px;
  }
  @media (max-width: 387px) {
    min-width: 250px;
  }
`;
export const InnerSectionsCommit = styled.div`
  width: 100%;
  min-width: 600px;
  text-align: left;

  @media (max-width: 769px) {
    min-width: auto;
  }
`;
export const InnerSectionBanner = styled.div`
  width: 100%;
  text-align: left;
  padding: 1rem 0.5rem;
  min-width: 500px;

  @media (max-width: 622px) {
    width: 100%;
    min-width: auto;
  }
`;
export const InnerSectionContent = styled.div`
  width: 100%;
  text-align: left;
  padding: 1rem 0.5rem;
  min-width: 500px;

  @media (max-width: 622px) {
    width: 100%;
    min-width: auto;
  }
  @media (max-width: 426px) {
    padding: 0;
  }
`;
export const InnerSectionBanners = styled.div`
  width: 100%;
  text-align: left;
  padding: 1rem 0.5rem;
  min-width: 700px;

  @media (max-width: 622px) {
    width: 100%;
  }
`;
export const InnerSectionBannersDash = styled.div`
  width: 100%;
  text-align: left;
  padding: 0rem 0rem;
  min-width: 650px;

  @media (max-width: 622px) {
    width: 100%;
  }
`;
export const InnerSectionWrappers = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0.5rem 1rem 0.5rem;
  min-width: 650px;

  @media (max-width: 622px) {
    width: 100%;
  }
`;

export const MainTitle = styled.div`
  width: 100%;
  font-size: 1.5rem;
  color: #000;
`;

export const Title = styled.div`
  width: 100%;
  font-size: 1.2rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262D")};
  font-weight: 600;
  margin-bottom: 1rem;
`;
export const TitleDesc = styled.div`
  width: 100%;
  font-size: 0.9rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#667085")};
  font-weight: 400;
  margin-bottom: 0.5rem;
`;

export const Subtitle = styled.div`
  width: 100%;
  font-size: 1rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#667085")};
  font-weight: 400;
  line-height: 25px;
  /* text-transform: capitalize; */
`;
export const ListView = styled.ul`
  padding: 0 1rem;
`;
export const SubtitleWar = styled.li`
  width: 100%;
  font-size: 1rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#667085")};
  font-weight: 400;
  line-height: 25px;
`;
export const BtnGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
export const BtnClose = styled.button`
  width: 100%;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
  background-color: ${(props) =>
    props.themeColor ? "#0C111D" : "transparent"};
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#000000")};
  box-shadow: ${(props) =>
    props.themeColor ? "0px 1px 2px 0px #1018280D" : ""};
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #b3b9c6"};
  padding: 1.6rem 0;
  line-height: 0;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
`;
export const ComButton = styled.button`
  width: 50%;
  background-color: ${(props) =>
    props.color
      ? props.backColor
      : props.themeColor
      ? "#0C111D"
      : "transparent"};
  color: ${(props) =>
    props.color ? props.color : props.themeColor ? "#CECFD2" : "#3D424F"};
  border: ${(props) =>
    props.color
      ? props.themeColor
        ? "1px solid transparent"
        : `1px solid ${props.borderColor}`
      : props.themeColor
      ? "1px solid #333741"
      : "1px solid #B3B9C6"};
  padding: 1.4rem 0;
  line-height: 0;
  box-shadow: ${(props) =>
    props.themeColor ? "0px 1px 2px 0px #1018280D" : ""};
  border-radius: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
`;
export const WarnIconBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  img {
    height: 60px;
    width: 60px;
    object-fit: contain;
  }
`;
export const SuccessIconBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;

  img {
    height: 60px;
    width: 60px;
    object-fit: contain;
  }
`;
// Delete Box

export const DeleteContainer = styled.div`
  padding: 1rem 0;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 14px 14px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  position: fixed;
  bottom: 0;
  left: 0;

  @media (max-width: 769px) {
    display: flex;
  }
  @media (max-width: 601px) {
    padding: 0.5rem 0;
  }
`;
export const DeleteComButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  font-weight: 400;
  /* margin-right: 3rem; */

  img {
    height: 22px;
    width: 22px;
    object-fit: contain;
    margin-bottom: 0.6rem;
  }
`;
export const RespBox = styled.div`
  display: none;
  @media (max-width: 769px) {
    display: flex;
  }
`;
// Name Temp
export const LangBox = styled.div`
  display: flex;
  align-items: center;
  /* position: absolute;
  top: 0;
  margin-top: -15px; */
`;
export const LangCircle = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #f05d24;
  padding: 5px 10px;
  border-radius: 50%;
  margin-left: -3px;
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#edeef1")};
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1.5px solid #ffffff"};
  font-size: 0.8rem;
  text-transform: capitalize;
  transform: scale(1.2);
`;
export const LangPlusCon = styled.div`
  color: ${(props) => (props.themeColor ? "#94969C" : "#464c5e")};
  font-weight: 400;
  font-size: 1rem;
`;
export const CrossRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
  }
`;
export const PagiButton = styled.div`
  border: 1px solid #d7dae0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  left: 0;
  margin-left: 1rem;
  border: ${(props) =>
    props.themeColor ? "1px solid #334155" : "1px solid #D7DAE0"};
  background-color: ${(props) =>
    props.themeColor ? "#161B26" : "transparent"};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  p {
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    font-weight: 500;
  }
  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
  }
`;
export const PagiButtonNext = styled.div`
  border: 1px solid #d7dae0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  right: 0;
  margin-right: 1rem;
  border: ${(props) =>
    props.themeColor ? "1px solid #334155" : "1px solid #D7DAE0"};
  background-color: ${(props) =>
    props.themeColor ? "#161B26" : "transparent"};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  p {
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    font-weight: 500;
  }
  img {
    height: 14px;
    width: 14px;
    object-fit: contain;
  }
`;

// Filter Options
export const FilterOptions = styled.div`
  width: 25%;
  display: flex;
  /* display: ${(props) => (props.isOpen ? "flex" : "none")}; */
  flex-direction: column;
  justify-content: left;
  align-items: center;
  position: absolute;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  right: 0;
  top: 0;
  margin-top: 2.5rem;
  /* margin-right: 1rem; */
  box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  border-radius: 8px;
  z-index: 9999;

  @media (max-width: 769px) {
    width: 70%;
  }
`;
export const FilterHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  p {
    font-weight: 600;
    font-size: 1rem;
    color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  }

  img {
    height: 10px;
    width: 10px;
    cursor: pointer;
    object-fit: contain;
  }
`;
export const ComTitle = styled.div`
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  font-weight: 500;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none !important;
`;
export const ComTitleTwo = styled.div`
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  font-weight: 500;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ComCatTitle = styled.div`
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  font-weight: 500;
  white-space: nowrap;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MediaIconBox = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  display: flex;
  align-items: center;
  margin-top: 5px;
  gap: 5px;
  cursor: pointer;

  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
    margin: 0 !important;
  }
`;
export const MediaIconBoxCou = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#85888E" : "#363A44")};
  display: flex;
  align-items: center;
  margin-top: 5px;
  gap: 5px;
  cursor: pointer;

  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
    margin: 0 !important;
  }
`;
// Image
export const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  padding-bottom: 1rem;
  img {
    height: 400px !important;
    object-fit: contain;
    width: 100%;
  }
`;
export const NoDataContainer = styled.div`
  width: 100%;
  margin: 1rem 0;

  p {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: ${(props) => (props.themeColor ? "#161B26" : "#24262D")};
  }
`;
export const ReadMoreText = styled.div`
  cursor: ${commonStyles.pointer};
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    color: ${(props) =>
      props.themeColor ? colorProvider.greyFive : colorProvider.comLink};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    border-bottom: ${(props) =>
      props.themeColor
        ? `1px solid ${colorProvider.greyFive}`
        : `1px solid ${colorProvider.greyFive}`};
    padding-bottom: 0.01rem;
    margin-top: 0.5rem;
    text-align: left;
  }
`;
export const ArchiveBox = styled.div`
  p {
    background-color: #fffbed;
    padding: 2px 5px;
    color: #f79009;
    font-weight: 500;
    font-size: 0.7rem;
  }
`;
