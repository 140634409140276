import styled from "styled-components/macro";

export const FormBox = styled.div`
  width: 33%;
  height: auto;
  min-height: 300px;
  max-width: 424px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: black; */
  /* border-radius: 24px; */
  padding: 1rem 1.7rem;
  border-radius: 24px;
  background: rgba(221, 221, 221, 0.1);

  /* Background blur/xl */
  backdrop-filter: blur(20px);

  @media (max-width: 1128px) {
    width: 43%;
  }
  @media (max-width: 862px) {
    width: 52%;
  }
  @media (max-width: 769px) {
    width: 60%;
  }
  @media (max-width: 660px) {
    width: 66%;
  }
  @media (max-width: 601px) {
    width: 70%;
  }
  @media (max-width: 566px) {
    width: 80%;
  }
  @media (max-width: 496px) {
    width: 90%;
  }
  @media (max-width: 441px) {
    width: 95%;
  }
  @media (max-width: 417px) {
    padding: 1rem;
  }

  .otp {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 80%;
    align-items: center;
    margin: auto;
    margin-bottom: 1rem;
  }
  .verifyDiv {
    padding: 18px;
  }
  p {
    color: white;
    margin-bottom: 0px;
  }
  .inputStyle {
    width: 60px !important;
    height: 60px;
    border-radius: 7px;
    border: 0px;
    margin-left: 8px;
    margin-right: 8px;
    background: #dddddd;
    font-size: 2rem;
    outline: none;
    color: #363A44;
    font-weight: 600;
  }
  .inputStyle-error {
    width: 60px !important;
    height: 60px;
    border-radius: 7px;
    border: 1px solid red;
    margin-left: 8px;
    margin-right: 8px;
    background: transparent;
    font-size: 2rem;
    outline: none;
    color: red;
    font-weight: 600;
  }
  .inputStyle-success {
    width: 50% !important;
    height: 60px;
    border-radius: 7px;
    border: 1px solid #17B26A;
    margin-left: 8px;
    margin-right: 8px;
    background: transparent;
    font-size: 2rem;
    outline: none;
    color: #17B26A;
    font-weight: 600;
  }
  .p3 {
    font-size: 14px;
  }
  .resend {
    text-decoration: underline;
    font-size: 14px;
  }
  .otpElements {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 20px;
    margin-bottom: 50px;
    padding-left: 18px;
    justify-content: center;
  }
  
  .p1 {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    margin-top: 25px;
    font-size: 27px;
  }
  
  .p2 {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
  }

`;
export const ComFormBox = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const IconRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const IconBox = styled.div`
  background-color: #fff5ed;
  border-radius: 12px;
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  img {
    height: 27px;
    width: 27px;
    object-fit: contain;
  }
`;
export const FormTitle = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 2rem;
  color: white;
  margin-bottom: 0.5rem;
  font-family: "EB Garamond", serif;

  @media (max-width: 351px) {
    font-size: 1.8rem;
  }
`;
export const FormDesc = styled.div`
  /* width: 100%; */
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  color: #EDEEF1;
  margin-bottom: 2.3rem;
  line-height: 24px;
  
  @media (max-width: 394px) {
    font-size: 0.8rem;
  }
`;

export const EditIcon = styled.div`

  img {
    height: 28px;
    width: 28px;
    object-fit: contain;
    margin-left: 10px;
  }
`
export const CheckForRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  `;
export const ComBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;

  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
  }
`;
export const RemLable = styled.label`
  font-size: 0.8rem;
  color: #edeef1;
  margin-left: 5px;
  font-weight: 500;
  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const ForgotButton = styled.div`
  cursor: pointer;
  color: #f6591c;
  font-weight: 600;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const OrRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 1rem;
`;
export const LineImage = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    object-fit: contain;
  }
`;
export const OrText = styled.div`
  width: 10%;
  font-size: 0.9rem;
  color: white;
  font-weight: 500;
  text-align: center;
`;
export const BackRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d7dae0;
  font-weight: 400;
  cursor: pointer;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 8px;
  }

  cursor: ${(props) => (props.cursor ? "not-allowed" : "pointer")};

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }
`;
export const DidNotRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DidText = styled.div`
  color: #d7dae0;
  font-size: 1rem;
  font-weight: 400;
`;
export const ClickButton = styled.div`
  color: #f6591c;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 5px;
  cursor: pointer;
`;
export const DetailsBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
export const CommonDetail = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
`;
export const CheckIconBox = styled.div`
  margin-top: 2px;
  margin-right: 6px;
  img {
    height: 20px;
    width: 20px;
  }
`;
export const CheckText = styled.div`
  color: #edeef1;
  font-size: 1rem;
  font-weight: 400;
`;
export const BannerImageBox = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;

  @media (max-width: 769px) {
    width: 98%;
  }
`
export const BannerTitle = styled.div`
  width: 100%;
  display: flex;
  font-size: 1rem;
  color: #FFFFFF;
  font-weight: 500;
  margin-bottom: 1rem;
`
export const BannerImage = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

  img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 16px;
  }
`
export const ErrorMsg = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  p {
    padding-top: 5px;
    font-size: 1rem;
    color: red;
    text-align: left;
    font-weight: 500;
  }
`;
