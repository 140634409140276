import { getConfig } from "@testing-library/react";

export const RMlogin = {
  login_url: "admin/auth/login",
  otp_verification: "admin/auth/otpVerification",
  forget_password: "admin/auth/forgetPassword",
  reset_password: "admin/auth/resetPassword",
  getBanner: "admin/banner/public/exitsBannerDetails",
  login_azure: "azure/admin",
};
export const Users = {
  userList: "admin/auth/userList",
  adminUserList: "admin/auth/adminUserList",
  userRegister: "admin/auth/register",
  editRegister: "admin/auth/userUpdate",
  detailUser: "admin/auth/userDetails/",
  userDelete: "admin/auth/userDelete",
  userActiveToggle: "admin/auth/private/activeToggle",
};
export const Groups = {
  getGroups: "admin/group/listGroup",
  addGroups: "admin/group/addGroup",
};
export const Permissions = {
  getPermissions: "admin/permission/listPermission",
};
export const Categorys = {
  deleteCategory: "admin/categorie/delete",
  addCategory: "admin/categorie/add",
  getCategory: "admin/categorie/list",
  editCategory: "admin/categorie/edit",
};
export const Certificate = {
  deleteCertificate: "admin/categorie/delete",
  addCertificate: "admin/categorie/add",
  getCertificate: "admin/learnerCertificate/list",
  editCertificate: "admin/categorie/edit",
};
export const Badges = {
  deleteBadges: "admin/badge/delete",
  getBadges: "admin/badge/list",
  editBadges: "admin/badge/add",
};
export const Language = {
  getLanguage: "admin/language/list",
  deleteLanguage: "admin/language/delete",
  addLanguage: "admin/language/add",
  editLanguage: "admin/language/update/",
  courseLang: "admin/course/language/",
  updateLangauge: "admin/course/courseLanguageRemoveAndEdit",
  deleteLanguageOtp: "admin/language/deleteOtp",
  getCourseLanguage: "admin/clientLearner/course/list",
};
export const Designation = {
  getDesignation: "admin/designation/list",
  deleteDesignation: "admin/designation/delete",
  addDesignation: "admin/designation/create",
  bulkDesignation: "admin/designation/bulkCreate",
};
export const Department = {
  getDepartment: "admin/department/list",
  deleteDepartment: "admin/department/delete",
  addDepartment: "admin/department/create",
  bulkDepartment: "admin/department/bulkCreate",
};
export const Feedback = {
  addFeedback: "admin/feedback_form/add",
  editFeedback: "admin/feedback_form/add",
  getFeedback: "admin/feedback_form/list",
  deleteFeedback: "admin/feedback_form/delete",
  deltailFeedback: "admin/feedback_form/details/",
  selected: "admin/feedback_form/selected",
};
export const Courses = {
  addCourse: "admin/course/add",
  editCourse: "admin/course/edit",
  getCourse: "admin/course/new/list",
  getCategoryCourse: "admin/course/categorie",
  getCategoryClientCourse: "admin/report/client/courses",
  deleteCourse: "admin/course/delete",
  deltailCourse: "admin/course/details",
  deltailListCourse: "admin/course/detailsList/",
  categoryGroupCourse: "admin/categorie/CategoriesGroupList",
  courseArchived: "admin/course/archived",
  courseUnArchived: "admin/course/removeArchived",
};
export const CoursesNew = {
  addCourse: "admin/course/new/add",
  basicInfo: "admin/course/new/baiseInfo",
  episodes: "admin/course/new/episodes",
  isFeedback: "admin/course/new/feedback",
  episodeList: "admin/course/new/episodes/list",
  deleteEpisode: "admin/course/new/episode/delete",
  getBasicInfo: "admin/course/new/baiseInfo/list",
  getFeedback: "admin/feedback_form/course/list",
  episodePosition: "admin/course/new/episode/episodePositionUpdate",
};
export const Episode = {
  getEpisode: "admin/course/episode/",
};
export const Quizz = {
  addQuizz: "admin/quiz/createAssessmentQuiz",
  editQuizz: "admin/quiz/Quiz/edit/",
  getQuizz: "admin/quiz/list",
  getClientQuizz: "admin/client/course/quiz/list",
  detailQuizz: "admin/quiz/details/",
  deleteQuizz: "admin/quiz/delete",
  mapQuiz: "admin/course/new/episode/mapQuiz",
  getEpisodeDetail: "admin/quiz/clientEpisodeDetails/",
  addFreeQuizz: "admin/quiz/createFreeLevelAssessmentQuiz",
  clientCustomCreateQuiz: "admin/quiz/clientCustomCreateQuiz",
};
export const Banner = {
  addBanner: "admin/banner/addbanner",
  editBanner: "admin/banner/edit",
  getBanner: "admin/banner/list",
  detailBanner: "admin/banner/test",
  deleteBanner: "admin/banner/delete",
  getMusicFile: "admin/music/list",
  addEditMusicFile: "admin/music/add",
  deleteMusicFile: "admin/music/delete",
};
export const Client = {
  addClient: "admin/client/add",
  addClientBilling: "admin/client/biling_info_create_and_update",
  addClientUrl: "admin/client/client_url_update",
  editClient: "admin/Client/edit",
  getClient: "admin/client/list",
  getClientDetail: "admin/client/details/",
  getClientCourseList: "admin/client/course/list/",
  detailClient: "admin/Client/test",
  deleteClient: "admin/Client/delete",
  addClientCourse: "admin/client/course/create",
  clientCourseDetail: "admin/client/course/details/",
  getLearner: "admin/clientLearner/list",
  addLearner: "admin/clientLearner/create",
  updateLearner: "admin/clientLearner/update",
  getLearnerNew: "admin/clientLearner/new/list",
  addLearnerNew: "admin/clientLearner/new/create",
  updateLearnerNew: "admin/clientLearner/new/update",
  createPolicy: "admin/client/policyDocuments/create",
  listPolicy: "admin/client/policyDocuments/list",
  uploadExcel: "admin/private/clientLearner/excel/upload/",
  getSetting: "admin/client/course/courseMightInterest/",
  getSettingCategory: "admin/client/course/categorieMightInterest/",
  replaceTrailer: "admin/interestMapping/repliceTrailer",
  editClientInfo: "",
  editCheckBox: "admin/client/course/courseMightInterestToggle",
  updateCheckMightInterest:
    "admin/client/course/clientInformationsMightInterestToggle",
  clientSPOCandAdminDelete: "admin/client/client_admin_spoc_remove",
  listClientSpoc: "admin/client/client_admin_spoc_list",
  addClientSpoc: "admin/client/client_admin_spoc_create",
  getClientCourseListDrop: "admin/client/client_course_list",
  deleteClientSpoc: "admin/client/client_course_delete/",
  impersonateRedirect: "admin/clientLearner/impersonateRedirect/",
  deletePolicy: "admin/client/policyDocuments/delete",
  deleteLearner: "admin/clientLearner/delete",
  toggleClientAdminSpoc: "admin/client/learner_convert_clientAdmin_toggle",
  getActiveCourse: "admin/client/course/ActiveLog",
  activeLogApprove: "admin/client/course/ActiveLog/",
  zohoAllClient: "admin/client/zoho/all_client/sync",
};
export const ClientGroups = {
  addClientGroup: "admin/client/group/create",
  getClientList: "admin//client/group/clientList",
  getClientGroupList: "admin/client/group/list",
  deleteClientGroup: "admin/client/group/delete",
};
export const CourseAss = {
  getLanguage: "admin/client/course/custom/list",
  getBasicInfo: "admin/course/client/baiseInfo/list",
  basicInfo: "admin/course/client/baiseInfo",
  episodeList: "admin/client/course/custom/episodes/list",
  episodes: "admin/client/course/custom/episode/add",
  getEpisodeDetail: "admin/client/course/custom/episode/details",
  getFeedbacks: "admin/feedback_form/client/course/list",
  updateFeedbacks: "admin/feedback_form/client/select",
  configUpdate: "admin/client/course/config/update",
  clientLogoUpdate: "admin/client/course/config/logo/update",
};
export const BulkDelete = {
  deleteCourses: "admin/course/courseBulkDeleteList",
  deleteEpisodes: "admin/delete/episode/delete",
  deleteCourso: "admin/delete/course/delete",
  // getCategory: "admin/delete/categorie/list",
  getCategory: "admin/delete/categorie/view/list",
  getCategoryTwo: "admin/categorie/list",
  getClientCategory: "admin/delete/client/categories/list",
  getCourse: "admin/delete/course/list",
  getClientCourse: "admin/delete/client/course/list",
  getEpisodes: "admin//delete/episode/list",
  getLearners: "admin/delete/learnerList",
  deleteLearner: "admin/delete/learnerDelete",
  getClient: "admin/delete/client/list",
  learnerBulkDelete: "admin/delete/learnerDeleteXsle",
};
export const Survey = {
  getSurvey: "admin/survey/group/list",
  deleteSurvey: "admin/survey/group/delete",
  addSurvey: "admin/survey/group/create",
  addSurveyForm: "admin/survey/form/create",
  getSurveyForm: "admin/survey/form/list",
  detailsSurveyForm: "admin/survey/form/details/",
  deleteSurveyForm: "admin/survey/form/delete",
  sendMail: "admin/survey/form/sendMail",
  pplToggle: "admin/survey/form/pplToggle",
  reportGenerate: "admin/survey/form/reportGenrate/",
  activeLog: "admin/survey/form/ActiveLog",
};
export const InterestMap = {
  getInterest: "admin/interestMapping/list",
  deleteInterest: "admin/interestMapping/delete",
  addInterest: "admin/interestMapping/create",
};
export const Industry = {
  getIndustry: "admin/industry/list",
  deleteIndustry: "admin/industry/delete",
  addIndustry: "admin/industry/create",
  bulkIndustry: "admin/industry/bulkUpload",
};
export const City = {
  getPincode: "admin//client/group/filter/pincode",
  getCity: "admin//client/group/filter/city",
};
export const UserType = {
  getUserType: "admin/userType/list",
  deleteUserType: "admin/userType/delete",
  addUserType: "admin/userType/create",
};
export const Commiittee = {
  getCommiittee: "admin/commitee/list",
  deleteCommiittee: "admin/commitee/delete",
  addCommiittee: "admin/commitee/create",
};
export const ComMember = {
  getComMember: "admin/commitee/member/list",
  addComMember: "admin/commitee/member/create",
  delComMember: "admin/commitee/member/delete",
};
export const Remainder = {
  getReminder: "admin/setReminder/list",
  deleteReminder: "admin/setReminder/delete",
  addReminder: "admin/setReminder/create",
};
export const SpecialCertificatess = {
  getSpecialCertificates: "admin/learnerCertificate/specialCertificates/list",
  addSpecialCertificates: "admin/learnerCertificate/specialCertificates",
  getTemplateSpecial: "admin/templateSpecialCertificate",
  sendIndividual: "admin/learnerCertificate/specialCertificatesSendIndividual",
  getCertiiDatail: "admin/learnerCertificate/specialCertificates/details/",
  sendMail: "admin/learnerCertificate/CertificateSendClientGroup",
  certificateId: "admin/learnerCertificate/edit",
  individualLearnerDelete: "admin/learnerCertificate/IndividualUserDelete",
  deleteSpecialCerti: "admin/learnerCertificate/specialCertificates/delete",
  activLog: "admin/learnerCertificate/activeLog",
};
export const ExternalLearner = {
  addLearner: "admin/createExternalLearner",
};
export const ProfileInfo = {
  getProfileDetails: "admin/auth/private/profile/details",
  updateProfile: "admin/auth/private/profile/update",
  changePassword: "admin/auth/private/profile/changePassword",
  companyDetails: "admin/configuration/AccountInfo",
};
export const AuditLog = {
  getAudits: "admin/audit_log/list",
};
export const DashboardAP = {
  getClientLearnerCourseInfo: "admin/dashboard/client_learner_course_info",
  getCourseInfo: "admin/dashboard/courseInfo",
  getNewClientRenewed:
    "admin/dashboard/new_client_renewed_licenses_additional_licenses",
  getSubscriptions: "admin/dashboard/subscriptionsExpiring",
  // getCategoryChartData: "admin/dashboard/LicensesSoldGraphCategories",
  getPopularCourse: "admin/dashboard/popularCourse",
  getCourseCompletion: "admin/dashboard/courseCompletion",
  getCategoryChartData: "admin/dashboard/allCategorieLicensesSoldGraph",
  getNumberOfCourse: "admin/dashboard/chart/area_chart_courses",
  getNumberOfClient: "admin/dashboard/chart/area_client",
  getNumberOfLearner: "admin/dashboard/chart/area_chart_learner",
  getActivityFeed: "admin/dashboard/activeFeed",
};
export const Configurations = {
  updateConfig: "admin/configuration/update",
  getConfig: "admin/configuration/getData",
};
export const GetNotification = {
  getNotification: "admin/auth/private/notifications",
};
export const Reports = {
  getCourseReport: "admin/report/courseWise",
  getEntryWise: "admin/report/client/learnerReport",
  listReportShedule: "admin/report/listReportSchedule",
  createReportShedule: "admin/report/createReportSchedule",
  deleteReportShedule: "admin/report/deleteReportSchedule",
};
export const EmailTemplate = {
  createEmailTempalte: "admin/email_template/create",
  getEmailTemp: "admin/email_template/list",
};
