import { decryptData } from "../utils/Helpers";
import {
  USER_DATA,
  IS_DARK,
  IS_TOGGLE,
  ACCESS_MODULE,
  SIDEBAR_TOGGLE,
  DELETE_LENGTH,
  SIDEBAR_TOGGLE_RESP,
  IS_MODAL,
  PAGE_COUNT,
  MAIN_CLIENT_ID,
  MAIN_COURSE_ID,
  FIRST_MENU_ACTIVE,
  SECOND_MENU_ACTIVE,
  COURSE_ACTIVE,
  ASSESS_ID,
  INTERACTID,
  LANGUAGEID,
  COURSEID,
  CATEGORYIDO,
  COURSEMENU,
  LANGUAGEIDOOO,
  COURSELANGUAGEID,
  COURSEMASTERID,
  COURSETYPE,
  EPISODEDATA,
  FEEDBACKTYPE,
  ASSESSOBJ,
  INTEROBj,
  ERROROBJ,
  ERRORPOPMSG,
  PROFILEUPDATE,
  PROFILEDATA,
  SUCCESS_POP,
} from "./actionTypes";
import CryptoJS from "crypto-js";

const secretKeyMain = "rmAdminSecretKey";
const encryptedRainDataToken = localStorage.getItem("rainDataToken");
const encryptedAccessToken = localStorage.getItem("accessToken");
const encryptedFirstMenuActive = localStorage.getItem("firstMenuActive");
const encryptedSecondMenuActive = localStorage.getItem("secondMenuActive");
const encryptedUserData = localStorage.getItem("rainRMUserData");

const data = decryptData(encryptedRainDataToken);
const firstMenuActive = decryptData(encryptedFirstMenuActive);
const secondMenuActive = decryptData(encryptedSecondMenuActive);
let userDataRM = decryptData(encryptedUserData);
let theme = localStorage.rainTheme || false;
let toggle = localStorage.isToggle;
let pageCount = localStorage.pageCount;
let mainClientId = localStorage.mainClientId;
let access_token = null;
if (encryptedAccessToken) {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedAccessToken, secretKeyMain);
    access_token = bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Access token decryption error:", error);
  }
}

const initialState = {
  userData: data,
  isDark: theme,
  isToggle: toggle,
  accessModule: data?.data?.permissions,
  isSidebarToggle: false,
  deleteLength: [],
  sidebarIsOpen: false,
  isModal: false,
  pageCount: Number(pageCount) || 1,
  mainClientId: mainClientId || "",
  mainCourseId: {},
  firstMenuActive: firstMenuActive,
  secondMenuActive: secondMenuActive,
  courseActive: false,
  assessmentId: "",
  interactiveId: "",
  languageId: null,
  courseId: null,
  categoryIdo: null,
  languageIdooo: null,
  courseMenu: {
    isBasic: true,
    isCourse: false,
    isFeedback: false,
    isLearner: false,
  },
  courseLangId: null,
  access_token: access_token,
  courseMasterId: null,
  courseType: null,
  episodeData: {
    title: "",
    url: "",
    fileSize: "",
    status: "",
    courseEpisodeeName: "",
    courseDescription: "",
    courseTags: [],
    videoTime: "",
    resolutions: [],
    convert_url: "",
  },
  feedbackType: null,
  assessObjo: null,
  interObjo: null,
  errorPop: false,
  errorPopMsg: null,
  profileUpdate: 1,
  profileData: userDataRM,
  successPop: false,
};

const customReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case USER_DATA:
      return {
        ...state,
        userData: data,
      };
    case IS_DARK:
      return {
        ...state,
        isDark: data,
      };
    case IS_TOGGLE:
      return {
        ...state,
        isToggle: data,
      };
    case ACCESS_MODULE:
      return {
        ...state,
        accessModule: data,
      };
    case SIDEBAR_TOGGLE:
      return {
        ...state,
        isSidebarToggle: data,
      };
    case DELETE_LENGTH:
      return {
        ...state,
        deleteLength: data,
      };
    case SIDEBAR_TOGGLE_RESP:
      return {
        ...state,
        sidebarIsOpen: data,
      };
    case IS_MODAL:
      return {
        ...state,
        isModal: data,
      };
    case PAGE_COUNT:
      return {
        ...state,
        pageCount: data,
      };
    case MAIN_CLIENT_ID:
      return {
        ...state,
        mainClientId: data,
      };
    case MAIN_COURSE_ID:
      return {
        ...state,
        mainCourseId: data,
      };
    case FIRST_MENU_ACTIVE:
      return {
        ...state,
        firstMenuActive: data,
      };
    case SECOND_MENU_ACTIVE:
      return {
        ...state,
        secondMenuActive: data,
      };
    case COURSE_ACTIVE:
      return {
        ...state,
        courseActive: data,
      };
    case ASSESS_ID:
      return {
        ...state,
        assessmentId: data,
      };
    case INTERACTID:
      return {
        ...state,
        interactiveId: data,
      };
    case LANGUAGEID:
      return {
        ...state,
        languageId: data,
      };
    case COURSEID:
      return {
        ...state,
        courseId: data,
      };
    case CATEGORYIDO:
      return {
        ...state,
        categoryIdo: data,
      };
    case COURSEMENU:
      return {
        ...state,
        courseMenu: data,
      };
    case LANGUAGEIDOOO:
      return {
        ...state,
        languageIdooo: data,
      };
    case COURSELANGUAGEID:
      return {
        ...state,
        courseLangId: data,
      };
    case COURSEMASTERID:
      return {
        ...state,
        courseMasterId: data,
      };
    case COURSETYPE:
      return {
        ...state,
        courseType: data,
      };
    case EPISODEDATA:
      return {
        ...state,
        episodeData: data,
      };
    case FEEDBACKTYPE:
      return {
        ...state,
        feedbackType: data,
      };
    case ASSESSOBJ:
      return {
        ...state,
        assessObjo: data,
      };
    case INTEROBj:
      return {
        ...state,
        interObjo: data,
      };
    case ERROROBJ:
      return {
        ...state,
        errorPop: data,
      };
    case ERRORPOPMSG:
      return {
        ...state,
        errorPopMsg: data,
      };
    case PROFILEUPDATE:
      return {
        ...state,
        profileUpdate: data,
      };
    case PROFILEDATA:
      return {
        ...state,
        profileData: data,
      };
    case SUCCESS_POP:
      return {
        ...state,
        successPop: data,
      };
    default:
      return state;
  }
};

export default customReducer;
