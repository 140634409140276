import React, { useState, Suspense, useEffect, lazy, useRef } from "react";
import messageObj from "./utils/messages.json";

import { Route, Routes, Navigate } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { publicRoutes, learnerRoutes, privateRoutes } from "./routes";
import * as CS from "./styles/CommonStyled";
import * as SEC from "./styles/SectionStyled";
import DetailLoader from "./components/DetailLoader";
import * as LE from "./styles/LoginStyled";
import bannerSample from "./assets/images/bannerSample.png";
import backOne from "./assets/images/backone.png";
import backVideo from "./assets/images/backVideo.mp4";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Sidebar from "./components/Sidebar/Sidebar";
import Navbar from "./components/Navbar/Navbar";
import axios from "./utils/axios";
import { RMlogin } from "./utils/api";
import { useDispatch } from "react-redux";
import { Modal } from "./components/Modal/Modal";
import * as UE from "./pages/private/User/UserElement";
import warn from "./assets/images/warns.png";
import { setErrorPop, setSuccessPop } from "./store/action";
import LayoutPage from "./Layout.js";
import successModal from "./assets/images/successModal.png";
import { ModalSuccess } from "./components/Modal/ModalSuccess.js";
const PageNotFound = lazy(() => import("./pages/notfound/PageNotFound.js"));

const PublicRoute = () => {
  const videoRef = useRef(null);
  let navigate = useNavigate();
  let location = useLocation();
  const [bannerData, setBannerData] = useState([]);
  const getBanner = async () => {
    let url = RMlogin.getBanner;
    try {
      const { data } = await axios.get(url);
      setBannerData(data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <Routes>
      {publicRoutes.map((val, ind) => {
        return (
          <Route
            exact
            key={ind}
            path={val.path}
            element={
              <Suspense fallback={<DetailLoader />}>
                <CS.MainContainer>
                  <CS.InnerContainer>
                    <CS.BackContainer>
                      <video
                        ref={videoRef}
                        className="video-background"
                        width="320"
                        height="240"
                        autoPlay
                        loop
                        muted
                      >
                        <source src={backVideo} type="video/mp4" />
                      </video>
                      {/* <img src={backOne} alt=""/> */}
                      <val.component
                        navigate={navigate}
                        location={location}
                        handlePauseVideo={pauseVideo}
                      />
                      <LE.BannerImageBox>
                        <LE.BannerTitle>
                          {bannerData?.showTextStatus
                            ? `${bannerData?.showText}`
                            : ""}
                        </LE.BannerTitle>
                        {bannerData?.showBannerImageStatus ? (
                          <LE.BannerImage
                            href={bannerData?.bannerLink}
                            target="_blank"
                          >
                            <img src={bannerData?.bannerImage} alt="" />
                          </LE.BannerImage>
                        ) : (
                          ""
                        )}
                      </LE.BannerImageBox>
                    </CS.BackContainer>
                  </CS.InnerContainer>
                </CS.MainContainer>
              </Suspense>
            }
          />
        );
      })}
      <Route path="/page-not-found" element={<PageNotFound />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};
const PrivateRoute = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let dispatch = useDispatch();
  const [messageState, setMessageState] = useState(messageObj);
  const theme = useSelector((state) => state?.isDark);
  const toggle = useSelector((state) => state?.isToggle);
  const sidebarToggle = useSelector((state) => state?.isSidebarToggle);
  const pageCount = useSelector((state) => state?.pageCount);
  const errorPop = useSelector((state) => state?.errorPop);
  const successPop = useSelector((state) => state?.successPop);
  const errorPopMsg = useSelector((state) => state?.errorPopMsg);
  const handleClose = () => {
    dispatch(setErrorPop(false));
    dispatch(setSuccessPop(false));
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<LayoutPage />}>
          {privateRoutes.map((val, ind) => (
            <Route
              key={ind}
              path={val.path}
              element={
                <Suspense>
                  <val.component
                    navigate={navigate}
                    dispatch={dispatch}
                    location={location}
                    pageCount={pageCount}
                    messageState={messageState}
                  />
                </Suspense>
              }
            />
          ))}
        </Route>
        <Route path="/*" element={<Navigate to="/dashboard" />} />
      </Routes>
      <ModalSuccess
        isOpen={errorPop}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBox>
              <img src={theme ? warn : warn} alt="danger" />
            </SEC.SuccessIconBox>
          </>
        }
        content={
          <SEC.InnerSectionSuccessTwo>
            <SEC.Title themeColor={theme}>Warning!</SEC.Title>
            <SEC.Subtitle themeColor={theme}>{errorPopMsg}</SEC.Subtitle>
            <SEC.BtnClose
              style={{ width: "100%", margin: "1rem 0" }}
              themeColor={theme}
              color={"#FFFFFF"}
              borderColor={"#B3B9C6"}
              backColor={"#E0271A"}
              type="primary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </SEC.BtnClose>
          </SEC.InnerSectionSuccessTwo>
        }
      />
      <ModalSuccess
        isOpen={errorPop}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBox>
              <img src={theme ? warn : warn} alt="danger" />
            </SEC.SuccessIconBox>
          </>
        }
        content={
          <SEC.InnerSectionSuccessTwo>
            <SEC.Title themeColor={theme}>Warning!</SEC.Title>
            <SEC.Subtitle themeColor={theme}>{errorPopMsg}</SEC.Subtitle>
            <SEC.BtnClose
              style={{ width: "100%", margin: "1rem 0" }}
              themeColor={theme}
              color={"#FFFFFF"}
              borderColor={"#B3B9C6"}
              backColor={"#E0271A"}
              type="primary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </SEC.BtnClose>
          </SEC.InnerSectionSuccessTwo>
        }
      />
      <ModalSuccess
        isOpen={successPop}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBox>
              <img
                src={theme?.isDark ? successModal : successModal}
                alt="danger"
              />
            </SEC.SuccessIconBox>
          </>
        }
        content={
          <SEC.InnerSection>
            <SEC.Title themeColor={theme?.isDark}>Great!</SEC.Title>
            {/* <SEC.TitleDesc themeColor={theme}>
                (Category contains associated content or)
              </SEC.TitleDesc> */}
            <SEC.Subtitle themeColor={theme?.isDark}>
              Deleted successfully.
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                handleClose();
              }}
            >
              OK
            </SEC.GreenContinue>
          </SEC.InnerSection>
        }
      />
    </>
  );
};

const App = () => {
  const state = useSelector((state) => state.userData);
  return (
    <CS.ParentBox>{state ? <PrivateRoute /> : <PublicRoute />}</CS.ParentBox>
  );
};

export default App;
